<template>
    <v-container>
        <v-card>
            <v-card-title class="grey lighten-4">
                <b>REPARTO</b>
            </v-card-title>
            <v-card-text class="justify-center align-center ">
                <v-row>
                    <v-col cols="6" sm="3" md="3">
                        <v-menu
                            ref="menu"
                            v-model="menu3"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                            <template #activator="{ on, attrs }">
                                <v-text-field
                                    v-model="fechaFiltro"
                                    hide-details="auto"
                                    dense
                                    label="FILTRAR POR RANGO - MES"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                                <v-spacer />
                                <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" sm="3" md="3">
                        <v-btn @click="downloadXlsx" :loading="loading" color="primary"> DESCARGAR XLSX </v-btn>
                    </v-col>
                    <v-col cols="6" sm="3" md="3" class="mx-2">
                        <v-btn @click="downloadTransportReport" :loading="loading" dark color="blue"> REPORTE TRANSPORTE XLSX </v-btn>
                    </v-col>
                    <v-col cols="6" sm="3" md="3" class="mx-2">
                        <v-btn @click="sendOrdenRecojo" :loading="loading" dark color="sky"> REPORTE ORDEN RECOJO</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-text class="grey lighten-4">
                <v-row>
                    <v-col cols="8" sm="8" class="mx-2">
                        <v-select
                            clearable
                            chips
                            multiple
                            v-model="selectIdEstado"
                            :items="listaEstados"
                            item-text="description"
                            item-value="id"
                            label="Filtro Estado"
                        ></v-select>
                    </v-col>
                    <v-col cols="8" sm="8" class="mx-2">
                        <v-btn color="primary" :loading="loading3" @click="getListaVenta"> BUSCAR </v-btn>
                    </v-col>
                    <v-col cols="12" class="justify-center align-center mt-5">
                        <v-data-table multi-sort dense :headers="headers" :items="myListPedidos" :items-per-page="10" class="elevation-1">
                            <template v-slot:[`item.action`]="{ item }">
                                <div v-if="item.idEstado != 9" @click="getListaVenta">
                                    <v-btn color="error" x-small v-if="item.idEstado == 13 || item.idEstado != 8" @click="openDialogOne(item)"
                                        >POR ENTREGAR</v-btn
                                    >
                                    <v-btn color="primary" x-small v-else @click="openDialogTwo(item)"> EN CAMINO </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" max-width="400" v-if="selectedRepart.atributos">
            <v-card>
                <v-card-title class="text-h5"> Detallles Entrega </v-card-title>
                <v-card-text>
                    <v-form v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4>TRANSPORTISTA</h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        item-text="fullName"
                                        item-value="id"
                                        v-model="selectedRepart.atributos.idChofer"
                                        :items="listaChofer"
                                        label="Chofer"
                                        required
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        dense
                                        v-model="datos.atributos.placaVehiculo"
                                        :items="listTransport"
                                        placeholder="Placas"
                                        label="Placa de vehiculo"
                                        item-text="plate_number"
                                        item-value="id"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <h4>RECEPTOR</h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="selectedRepart.atributos.documentoReceptor"
                                        label="Documento Receptor"
                                        @keypress="isNumber"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="receptor.apellidoPaternoReceptor" label="Apellido Paterno" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="receptor.apellidoMaternoReceptor" label="Apellido Materno" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="receptor.nombreReceptor" label="nombreReceptor" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="selectedRepart.numeroComprobante"
                                        placeholder="F001-100"
                                        label="numeroComprobante"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="selectedRepart.numeroGuiaRemision"
                                        placeholder="G001-100"
                                        label="numeroGuiaRemision"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false"> Cancelar </v-btn>
                    <v-btn :disabled="!valid" :loading="loading" color="green darken-1" text @click="entregado(selectedRepart)"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="400">
            <v-card>
                <v-card-title class="text-h5">CONFIRMAR INICIO DEL RECORRIDO </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog2 = false"> Cancelar </v-btn>
                    <v-btn :disabled="!valid" :loading="loading" color="green darken-1" text @click="enCamino(selectedRepart)"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { format, subDays, addDays } from 'date-fns'
import { splitfirsLetter } from '@/utils/genericUtils'
import { exportXLSX } from '@/utils'
import { sleep } from '@/utils/genericUtils'

export default {
    mixins: [mixins],

    data() {
        return {
            selectIdEstado: null,
            menu3: false,
            fechaFiltro: [format(subDays(new Date(), 1), 'yyyy-MM-dd'), format(addDays(new Date(), 5), 'yyyy-MM-dd')],
            valid: true,
            receptor: {
                apellidoPaternoReceptor: '',
                apellidoMaternoReceptor: '',
                nombreReceptor: ''
            },
            polling: null,
            selectedRepart: {},
            documentoReceptor: '',
            placa: '',
            loading3: false,
            loading: false,
            chofer: null,
            dialog: false,
            dialog2: false,
            myListPedidos: [],
            headers: [
                { text: 'ACTIONS', value: 'action', sortable: false },
                { text: 'FEC. Entrega', value: 'check_in' },
                { text: 'GUIA REMISION', value: 'numeroGuiaRemision' },
                { text: 'ESTADO', value: 'estado' },
                { text: 'Ticket/OC', value: 'ticketOc' },
                { text: 'Productos', value: '_productos' },
                { text: 'nombreReceptor', value: 'atributos.fullNameReceptor' }

                // { text: 'PRODUCTO', value: 'productos' },
                // { text: 'FECHA', value: 'check_in' },
            ]
        }
    },
    computed: {
        ...mapState('utilities', ['itemPlacas']),
        ...mapState('reparto', ['listReparto', 'listTransport']),
        ...mapState('inventario', ['listaEstados']),
        ...mapGetters('users', ['listaChofer', 'listaUser']),
        ...mapState('users', ['listaUser', 'user']),
        ...mapState('ventas', ['datos']),
        isNumeroDocumento() {
            return this.selectedRepart.atributos ? this.selectedRepart.atributos.documentoReceptor : null
        }
    },
    async created() {
        await this.$store.dispatch('inventario/estados')
        await this.getListaVenta()
        this.$store.dispatch('users/getLista')
        this.pollData()
    },

    methods: {
        async downloadXlsx() {
            console.log('myListPedidos', this.myListPedidos)
            this.loading = true
            const items = []
            const headers = ['TICKET/OC', 'FECHA', 'PRODUCTO', 'NOMBRE', 'DIRECCION', 'DNI', 'GUIA', 'PROVINCIA', 'DEPARTAMENTO', 'DISTRITO', 'REFERENCIA']
            for (const x of this.myListPedidos) {
                const detalleUbigeo = x.ubigeo ? await this.$store.dispatch('reparto/detailsUbigeo', x.ubigeo) : null

                items.push({
                    ...x,
                    provincia: detalleUbigeo ? detalleUbigeo[0].provincia : null,
                    departamento: detalleUbigeo ? detalleUbigeo[0].departamento : null,
                    distrito: detalleUbigeo ? detalleUbigeo[0].distrito : null
                })
            }
            const filename = `REPARTO DIARIO ${this.fechaFiltro}`
            exportXLSX(
                {
                    headers,
                    campos: [
                        'ticketOc',
                        'check_in',
                        '_productos',
                        'full_name',
                        'direccion',
                        'numeroDocumento',
                        'numeroGuiaRemision',
                        'provincia',
                        'departamento',
                        'distrito',
                        'referencia'
                    ],
                    arrayData: items
                },
                { filename }
            )
            this.loading = false
        },
        async getListaVenta() {
            console.log('llego')
            if (this.selectIdEstado) {
                const body = {
                    idBussines: this.user.idBussines,
                    estadoVenta: this.selectIdEstado,
                    fechaFiltro: this.fechaFiltro
                }
                await this.$store.dispatch('reparto/getListReparto', { ...body })
                this._fnListaPedidos()
                this.menu3 = false
            }
        },
        async downloadTransportReport() {
            await this.$store.dispatch('reporte/reportTransport', this.myListPedidos)
        },
        async sendOrdenRecojo() {
            const data = await this.$store.dispatch('reporte/sendOrdenRecojo')
            console.log('datav2', data)
            if (data.code == '00') {
                this.$store.commit('mainUI/OPEN_MODAL', {
                    state: true,
                    text: 'Envio Exitoso',
                    color: 'green',
                    close:true
                })
            } else if (data.code == '01') {
                this.$store.commit('mainUI/OPEN_MODAL', {
                    state: true,
                    text: 'No se encontraron ordenes',
                    color: 'green',
                                        close:true

                })
            } else {
                this.$store.commit('mainUI/OPEN_MODAL', {
                    state: true,
                    text: 'Error',
                    color: 'green',
                                        close:true

                })
            }
        },

        openDialogOne(payload) {
            this.dialog2 = false
            this.dialog = true
            this.selectedRepart = payload
        },
        openDialogTwo(payload) {
            this.dialog2 = true
            this.selectedRepart = payload
        },
        pollData() {
            this.polling = setInterval(() => {
                this.getListaVenta()
            }, 300000)
        },
        async entregado(item) {
            console.log('item ', item)
            console.log('this.receptor ', this.receptor)
            console.log('AQUI--')
            this.loading = true
            const { apellidoPaternoReceptor, apellidoMaternoReceptor, nombreReceptor } = this.receptor
            const fullNameReceptor = `${apellidoPaternoReceptor} ${apellidoMaternoReceptor} ${nombreReceptor}`
            const atributos = { ...item.atributos, fullNameReceptor: fullNameReceptor.toLowerCase() }
            await this.$store.dispatch('reparto/entregadoSodimac', { ...item, atributos })
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Solicitud enviada a b2b correctamente. Verificar su estado en 1 minuto',
                color: 'green'
            })
            await sleep(3000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            this.loading = false
            this.dialog = false
            await this.getListaVenta()
            //setTimeout(() => location.reload(), 4000)
        },
        async enCamino(item) {
            this.loading = true
            console.log('enCamino item ', item)
            //this.loading3 = true
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Solicitud enviada a b2b correctamente. Verificar su estado en 1 minuto',
                color: 'green'
            })
            await sleep(5000)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            //await this.$store.dispatch('reparto/enCaminoSodimac', item)
            this.loading = false
            this.dialog2 = false
            await this.getListaVenta()
            await this.$store.dispatch('ventas/patchVenta', { ...item, idEstado: 13 })
            setTimeout(() => location.reload(), 2000)
        },
        _fnListaPedidos(val) {
            let item = []
            console.log('this.listReparto', this.listReparto)
            this.listReparto.map((x) => {
                const idOrdenCompra = x?.idOrdenCompra || ''
                console.log('idOrdenCompra', x.idOrdenCompra)
                console.log('this.listaUser', this.listaUser)
                console.log('x.idColaborador', x.idColaborador)
                if (this.listaUser.length > 0) {
                    const findCola = this.listaUser.find((y) => y.id == x.idColaborador)
                    const { nombreReceptors } = findCola || ''
                    console.log('x.productos ', x.productos)
                    //const productos =JSON.parse( x.productos).join(',')
                    if (val) {
                        const it = val.find((y) => y == x.idEstado)
                        if (it) {
                            item.push({
                                ...x,
                                ticketOc: `${x.id}-${idOrdenCompra}`,
                                atributos: x.atributos ? x.atributos : DefaultForm.formAttributes(),
                                nameColaborador: nombreReceptors,
                                _productos: x.productos
                            })
                        }
                    } else {
                        item.push({
                            ...x,
                            ticketOc: `${x.id}-${idOrdenCompra}`,
                            atributos: x.atributos ? x.atributos : DefaultForm.formAttributes(),
                            nameColaborador: nombreReceptors,
                            _productos: x.productos
                        })
                    }
                }
            })
            console.log('items --->', item)
            this.myListPedidos = item
            return item
        }
    },
    async mounted() {
        await this.$store.dispatch('proveedor/findTransport')
        await this.getListaVenta()
    },
    watch: {
        async isNumeroDocumento(val) {
            try {
                console.log('valk', val)
                if (val && val.length === 8) {
                    const data = await this.$store.dispatch('clientes/getClienteExternal', {
                        idTipoDocumento: 1,
                        numeroDocumento: val
                    })
                    console.log('data---------', data)
                    this.receptor.apellidoPaternoReceptor = splitfirsLetter(data.apellido_paterno)
                    this.receptor.apellidoMaternoReceptor = splitfirsLetter(data.apellido_materno)
                    this.receptor.nombreReceptor = splitfirsLetter(data.nombres)
                }
            } catch (error) {
                console.log('error', error)
            }
        },
        async selectIdEstado(val) {
            console.log('here-----', val)
            if (val) {
                await this._fnListaPedidos(val)
            }
        }
    }
}
</script>
